<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <div v-else class="custom-card">
      <div class="custom-card_header">
        <p class="custom-card__text">Управление профилем компании</p>
      </div>
      <p v-if="noProfile">У данного поставщика пока нет профиля</p>
      <v-btn v-if="noProfile" text @click="modal = true"
        ><v-icon color="#5d4bd0" class="mr-2">mdi-plus-circle</v-icon>Создать
        профиль
      </v-btn>

      <!-- данные профиля поставщика -->
      <section v-else class="custom-card__main">
        <div class="custom-card__top-block">
          <div class="custom-card__block">
            <div class="custom-card__block_btn">
              <v-btn icon large color="#5d4bd0" @click="enableRedactMode()">
                <v-icon>mdi-pencil-circle</v-icon>
              </v-btn>
            </div>
            <p class="font-weight-black">Информация :</p>
            <p class="font-weight-black">{{ providerName }}</p>
            <p v-if="profileData.profileCreated">
              <span class="font-weight-black">Профиль создан: </span
              >{{ getDate() }}
            </p>
            <p>
              <span class="font-weight-black">Своя доставка: </span
              >{{ profileData.selfDelivery ? "Да" : "Нет" }}
            </p>
            <p>
              <span class="font-weight-black">Доставка и склады Аяна: </span
              >{{ profileData.ayanDeliveryComposition ? "Да" : "Нет" }}
            </p>
            <p>
              <span class="font-weight-black">Успешных доставок: </span
              >{{ profileData.ordersDelivered }}
            </p>
            <p>
              <span class="font-weight-black">Доставлено вовремя заказов: </span
              >{{ profileData.deliveredOnTime }}
            </p>
          </div>
          <!-- картинка профиля -->
          <div class="custom-card__block">
            <div class="custom-card__block_btn">
              <v-btn icon large color="#5d4bd0" @click="enableRedactMode()">
                <v-icon>mdi-pencil-circle</v-icon>
              </v-btn>
            </div>
            <div v-if="profileData.image" class="mb-5">
              <v-img :src="profileData.image.fileUrl" width="200" />
            </div>
          </div>
          <div class="custom-card__block">
            <div class="custom-card__block_btn">
              <v-btn
                icon
                large
                color="#5d4bd0"
                @click="groupsList = !groupsList"
              >
                <v-icon>mdi-pencil-circle</v-icon>
              </v-btn>
            </div>
            <!-- блок с реквизитами поставщика -->
            <p class="font-weight-black">Основная категория продавца</p>
            <div
              v-if="
                profileData.provider && profileData.provider.categoryOwnership
              "
              class="blue-block"
            >
              {{ profileData.provider.categoryOwnership.name }}
            </div>
            <p v-else>Не указана</p>
            <div v-show="groupsList">
              <v-select
                v-model="categoryOwnership"
                label="Группы"
                :items="GROUPS"
                item-text="name"
                item-value="id"
              ></v-select>
              <v-btn color="#5d4bd0" dark small @click="addGroup()"
                >Добавить группу</v-btn
              >
              <v-btn icon color="error" @click="removeGroup()"
                ><v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <div class="custom-card__block_req">
          <div class="custom-card__block_btn">
            <v-btn icon large color="#5d4bd0" @click="requisitsModal = true">
              <v-icon>mdi-pencil-circle</v-icon>
            </v-btn>
          </div>
          <!-- блок с реквизитами поставщика -->
          <RequisitsBlock :requisits="profileRequisits" />
        </div>
      </section>
    </div>
    <!-- Диалоговое для редактирования или создания профиля -->
    <CreateProfile
      :modal.sync="modal"
      :create-data="createData"
      @create="addProfile($event)"
      @uploadFoto="uploadFoto($event)"
    />
    <!-- модалка с заполнением реквизитов -->
    <Requisits
      :requisits-modal.sync="requisitsModal"
      :provider-id="$route.params.id"
      :requisits="profileRequisits"
      @editRequisits="
        setValues();
        requisitsModal = false;
      "
    />
    <delete-dialog
      :show="showDeleteDialog"
      @close-modal="
        showDeleteDialog = false;
        currentPayment = null;
      "
      @delete-modal="deletePaymentType()"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import showMessage from "@/Functions/message";
import Requisits from "@/components/ProviderProfile/Requisits";
import RequisitsBlock from "@/components/ProviderProfile/RequisitsBlock";
import CreateProfile from "@/components/ProviderProfile/CreateProfile";
import DeleteDialog from "@/components/DeleteDialog";
import api from "@/api";

export default {
  components: {
    loader,
    Requisits,
    CreateProfile,
    DeleteDialog,
    RequisitsBlock,
  },
  data() {
    return {
      loading: false,
      modal: false,
      showDeleteDialog: false,
      providerName: "",
      noProfile: false,
      profileData: {},
      createData: {
        brandCount: 0,
        selfDelivery: false,
        ayanDeliveryComposition: false,
        providerId: 0,
        image: null,
      },
      editData: {},
      editMode: false,
      requisitsModal: false,
      profileRequisits: null,
      groupsList: false,
      categoryOwnership: null,
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Providers/STATE",
      CITY: "City/STATE",
      DEPARTMENT: "Department/STATE",
      GROUPS: "ProviderGroups/STATE",
    }),
  },
  mounted() {
    this.getProviderName();
    if (!this.GROUPS.length) {
      this.GET_ALL_GROUPS();
    }
  },
  methods: {
    ...mapActions({
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
      GET_ALL_GROUPS: "ProviderGroups/GET_ALL_GROUPS",
      ADD_GROUP_TO_PROVIDER: "ProviderGroups/ADD_GROUP_TO_PROVIDER",
      REMOVE_GROUP_FROM_PROVIDER: "ProviderGroups/REMOVE_GROUP_FROM_PROVIDER",
    }),
    //Подгружает профиль
    async setValues() {
      this.loading = true;
      let response;
      // либо поставщика либо филиала
      response = await api.Providers.getProviderProfile(this.$route.params.id);
      if (response?.type === "error") {
        this.noProfile = true;
      } else {
        this.noProfile = false;
        this.profileData = response.profile;
        this.profileRequisits = response.requisites;

        // удаляем ненужный объект для упрощения копирования объекта
        if (this.profileRequisits) {
          this.profileData.provider = this.profileRequisits.provider;
          delete this.profileRequisits.provider;
        }
      }
      this.loading = false;
    },

    setCurrentPaymentForDelete(id) {
      (this.currentPayment = id), (this.showDeleteDialog = true);
    },
    async deletePaymentType() {
      const payload = {
        departmentId: this.departmentId,
        paymentTypeId: this.currentPayment,
      };
      const response = await api.Payment.deleteDepartmentPayment(payload);
      if (response.type !== "error") {
        this.setValues();
        this.currentPayment = null;
        this.showDeleteDialog = false;
        showMessage("Тип оплаты удален", true);
      } else {
        showMessage(response.data.message);
      }
    },
    enableRedactMode() {
      this.createData.providerId = this.profileData.providerId;
      this.createData.brandCount = this.profileData.brandCount;
      this.createData.selfDelivery = this.profileData.selfDelivery;
      this.createData.ayanDeliveryComposition = this.profileData.ayanDeliveryComposition;
      this.createData.image = this.profileData.image;
      this.modal = true;
    },
    async addGroup() {
      this.loading = true;
      const body = {
        providerId: this.$route.params.id,
        groupId: this.categoryOwnership,
      };
      const response = await this.ADD_GROUP_TO_PROVIDER(body);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Группа добавлена", true);
        await this.setValues();
      }
      this.groupsList = false;
      this.loading = false;
    },
    async removeGroup() {
      this.loading = true;
      const response = await this.REMOVE_GROUP_FROM_PROVIDER(
        this.$route.params.id
      );
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Группа удалена", true);
        await this.setValues();
      }
      this.groupsList = false;
      this.loading = false;
    },
    // Создает либо изменяет профиль
    async addProfile(form) {
      if (form.validate()) {
        this.createData.providerId = this.$route.params.id;
        this.loading = true;
        let response;
        if (this.noProfile) {
          response = await api.Providers.createProviderProfile(this.createData);
        } else {
          response = await api.Providers.editProviderProfile(this.createData);
        }
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          // Загружаем картинку
          if (this.createData.image) {
            let formData = new FormData();
            formData.append("file", this.createData.file);
            let payload = {
              providerId: response.department
                ? response.department.id
                : this.$route.params.id,
              body: formData,
            };
            await api.Providers.addImageToProvider(payload);
          }
          showMessage(
            this.noProfile ? "Профиль создан" : "Профиль изменен",
            true
          );
          await this.setValues();
          this.modal = false;
        }
        this.loading = false;
      }
    },
    // Определяет имя поставщика основываясь на общем стейте, если он пуст перекидвает на пред страницу
    async getProviderName() {
      if (this.STATE.allProviders.length > 0) {
        await this.setValues();
        this.providerName = this.STATE.allProviders.find(
          (provider) => provider.id == this.$route.params.id
        ).name;
      } else {
        await this.getAllProviders();
        this.getProviderName();
      }
    },
    // возвращает дату в читаемом формате
    getDate() {
      if (this.profileData.profileCreated) {
        let date = this.profileData.profileCreated.slice(0, 10);
        let time = this.profileData.profileCreated.slice(11, 16);
        return `${date} ${time}`;
      }
    },
    uploadFoto(file) {
      if (file.size > 1000000) {
        showMessage("Размер файла превышает 1мб");
      } else {
        this.createData.file = file;
        this.createData.image = {};
        this.createData.image.fileUrl = URL.createObjectURL(file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  position: relative;
  padding: 0 10px 20px;
  &_header {
    background: linear-gradient(to right, #e93e7b 50%, white 70%);
    margin-left: -12px;
    color: white;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
  &__main {
    display: flex;
    flex-direction: column;
  }
  &__top-block {
    display: flex;
    gap: 30px;
  }
  &__text {
    margin: 0;
  }
  &__block {
    p {
      margin-bottom: 0;
    }
    &_btn {
      display: flex;
      justify-content: flex-end;
    }
    &_req {
      width: max-content;
    }
  }
}
.blue-block {
  background-color: #51c8f0;
  color: white;
  border-radius: 7px;
  padding: 5px 15px;
}
</style>
